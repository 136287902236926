<template>
  <div class="forgot">
    <div class="forgot-title">パスワードのお問い合わせ</div>
    <div class="forgot-docs">
      「<a href = "/terms.html" target="_blank">利用規約</a>」
      「<a href = "https://www.tkhs.co.jp/about/privacy.html" target="_blank">個人情報の取り扱いについて</a>」
      <span>に同意の上ご利用ください。</span>
    </div>
    <!-- <el-alert
      title="パスワード再設定用メールが送信できませんでした。登録されていないメールアドレスです。正しいメールアドレスを入力してください。"
      type="error">
    </el-alert> -->
    <div class="forgot-tips">
      <p>下記にログインIDを入力のうえ、「送信」ボタンを押してください。</p>
      <br>
      <p>入力されたログインIDのメールアドレス宛てに、パスワードの再設定用メールをお送りします。</p>
      <p>なお、ご登録いただいたパスワードのご案内はできません。</p>
      <br>
      <p>ログインIDを紛失された場合は、講座受講のお申し込み時のご連絡先窓口までお問い合わせください。</p>
    </div>
    <el-form ref="forgotForm" :model="form" class="app-form" :rules="rules">
      <el-form-item prop="username" label="ログインID" style="margin-bottom: 30px">
        <el-input v-model="form.username" autocomplete="off"
          placeholder="ログインID"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item>
        <el-date-picker
          v-model="form.birthday"
          type="date"
          placeholder="生年月日">
        </el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button class="studypack-button-primary" type="primary" @click="onSubmit">送信</el-button>
      </el-form-item>
      <router-link to="/login">ログインはこちらへ</router-link>
    </el-form>
  </div>
</template>

<script>
import tools from '@/utils/tools';
import request from '@/utils/request';
import Cookies from 'js-cookie';
import { Notification } from 'element-ui';

const { checkUserName } = tools;

export default {
  name: 'Forgot',
  data() {
    return {
      form: {
        username: '',
        // birthday: '',
      },
      rules: {
        username: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { max: 50, message: '50文字以内を入力ください。', trigger: 'blur' },
          { validator: checkUserName, trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.forgotForm.validate((valid) => {
        if (valid) {
          Cookies.remove('token');

          request('resetPassword', {
            userName: this.form.username,
          }).then(() => {
            Notification({
              title: '成功',
              message: 'パスワードお問い合わせが成功しました。お問い合わせのメールにてパスワード再設定ください。',
              // duration: 0,
              offset: 75,
              type: 'success',
            });
            this.$router.push({
              path: '/login',
            }).catch(() => {});
          });
        }
      });
    },
  },
};
</script>

<style lang="less">
.forgot {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin: 40px auto;
  max-width: 600px;

  .forgot-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .forgot-docs {
    text-align: center;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .el-alert {
    margin-bottom: 24px;
  }
  .forgot-tips {
    margin-bottom: 30px;
  }
}
</style>
